import React from "react";
import { useDateSegment } from "react-aria";
import styled from "styled-components";

export default function DateSegment({
  segment,
  state,
}) {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <Segment {...segmentProps} ref={ref} segment={segment}>
      {segment.text}
    </Segment>
  );
}

const Segment = styled.div`
  padding: 0 2px;
  font-variant-numeric: tabular-nums;
  text-align: end;
  color: ${({ segment }) => (segment.isPlaceholder ? "#687078" : "inherit")};
`;
