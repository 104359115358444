import TimeField from "./components/time_field";

function App() {
  return (
    <div style={{ maxWidth: "400px", backgroundColor: "#ededed" }}>
      <TimeField label="cheese" />
    </div>
  );
}

export default App;
