import React from "react";
import { useLocale, useTimeField } from "react-aria";
import styled from "styled-components";
import {
  parseAbsoluteToLocal,
  parseZonedDateTime,
  Time,
} from "@internationalized/date";
import { useTimeFieldState } from "react-stately";
import DateSegment from "./date_segment";

export function formatTimeStringLocal(string: string) {
  if (string[string.length - 1] === "]") {
    return parseZonedDateTime(string);
  }
  return parseAbsoluteToLocal(string);
}

export { Time };

export default function TimeField(props: TimeFieldProps) {
  const { locale } = useLocale();
  const state = useTimeFieldState({
    ...props,
    locale,
  });

  const ref = React.useRef(null);
  const { labelProps, fieldProps } = useTimeField(props, state, ref);
  console.log(state.value);
  return (
    <Wrapper style={props.styleOverrides?.wrapper}>
      <Label {...labelProps} style={props.styleOverrides?.label}>
        {props.label}
      </Label>
      <Field
        {...fieldProps}
        ref={ref}
        className="field"
        style={props.styleOverrides?.field}
      >
        {state.segments.map((segment, index) => (
          <DateSegment key={index} segment={segment} state={state} /> // eslint-disable-line react/no-array-index-key
        ))}
        {state.validationState === "invalid" && (
          <span aria-hidden="true">🚫</span>
        )}
      </Field>
      <input
        type="time"
        value={`${state.value?.hour}:${state.value?.minute}:${state.value?.millisecond}`}
        hidden
        readOnly
      />
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: CircularStd, Arial, Helvetica, sans-serif;
`;

export const Label = styled.span`
  margin-bottom: 5px;
`;

export const Field = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid dimgrey;
  width: 100%;
  color: rgb(104, 112, 120);
`;
